import { SvgIcon } from '@app/shared/svg-icons/svg-icons.enum'

import { NgIf } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core'
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'

import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [SvgIconsModule, MatIconModule, FormsModule, NgIf, MatFormField, MatInputModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() type = 'text'
  @Input() placeholder = 'Search'
  @Input() icon!: SvgIcon

  @Input() nonControlValue = ''

  @Input() isNonControl = false

  @Input() hasLabel = true

  @Input() isPasswordLogic!: boolean

  @Output() nonControlEvent: EventEmitter<string> = new EventEmitter<string>()

  svgIcons = SvgIcon

  private val = ''

  onChange: (value: string) => void = () => {}
  onTouched = (): void => {}

  currentType!: string

  get currentHideText(): string {
    return this.currentType === 'password' ? 'Показати' : 'Сховати'
  }

  get value(): string {
    return this.val
  }

  set value(val: string) {
    if (val !== this.val) {
      this.val = val
      this.onChange(val)
      this.onTouched()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['nonControlValue'] && !changes['nonControlValue'].firstChange) {
      this.writeValue(this.nonControlValue)
    }
  }

  ngOnInit(): void {
    if (this.isNonControl) {
      this.writeValue(this.nonControlValue)
    }

    this.currentType = this.type
  }

  toggleHiddenText(): void {
    this.currentType = this.currentType === 'password' ? 'text' : 'password'
  }

  writeValue(value: string): void {
    this.value = value
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  changeEvent(event: Event): void {
    this.nonControlEvent.emit((<HTMLInputElement>event.target).value)
  }
}
